import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useTolgee } from '@tolgee/vue';

export const useUserStore = defineStore('user', () => {
    const tolgee = useTolgee(['language']);
    const user = ref(null);
    const language = ref("en");

    const setUser = (data) => {
        user.value = data;
        setLanguage(data.language);
    };
    const isCompanyType = (type) => user.value?.company?.type === type;
    const checkoutRequired = computed(() => {
        return user.value?.company?.type === 1 && user.value?.company?.billingType === 1 && user.value?.company?.billingPlan === "FREE";
    });
    
    const showTour = ref(true);
    const isLoggedIn = computed(() => !!user.value);
    const csrfToken = computed(() => user.value?.csrfToken);

    const setLanguage = async (lang) => {
        language.value = lang;
        await tolgee.value.changeLanguage(lang);
    };
    return { user, language, csrfToken, setUser, setLanguage, isLoggedIn, isCompanyType, checkoutRequired, showTour };
});