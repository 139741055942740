import {Tolgee, DevTools  } from '@tolgee/vue';
import { FormatIcu } from '@tolgee/format-icu';
export const createTolgee = () => Tolgee()
  .use(DevTools())
  // .use(FormatSimple())
  .use(FormatIcu())
  .init({
    availableLanguages: ['en', 'nl'],
    defaultLanguage: 'en',

    // for development
    apiUrl: import.meta.env.VITE_APP_TOLGEE_API_URL,
    apiKey: import.meta.env.VITE_APP_TOLGEE_API_KEY,

    // for production
    staticData: {
        en: () => import('@/../i18n/en.json'),
        nl: () => import('@/../i18n/nl.json'),
    }
});

const formatMarkdown = (text = "", params = {}, options) => {
    // First escape any HTML tags
    const escapedText = text.replace(/[<>]/g, char => ({ '<': '&lt;', '>': '&gt;' }[char]));
    return escapedText
      // Handle bold first (so we don't interfere with italics parsing)
      .replace(/\*\*([^*]+?)\*\*/g, '<strong>$1</strong>')
      // Handle italics
      .replace(/\*([^*]+?)\*/g, '<em>$1</em>')
      // Handle inline code
      .replace(/`([^`]+?)`/g, '<code>$1</code>')
      // Handle links - convert [text]({ link }) to <a href={url} that can be filled by Tolgee
      // .replace(/\[([^\]]+?)\]/g, (_, text) => `<a href="${params.url || '#'}">${text}</a>`)
      .replace(/\[([^\]]+)\]\(([^)]+)\)/g, (_, text, url) => `<a href="${url.trim()}" target="_blank">${text}</a>`)
      // Handle soft line breaks (two spaces followed by newline)
      .replace(/\\n/g, '<br />');
}
export const tolgeeFormatPlugin = {
  install: (app, options = {}) => {
    // Add the $tf global method
    app.config.globalProperties.$tf = function(key, params = {}) {
      // Access the Tolgee instance through this.$tolgee
      const translated = this.$tolgee?.t(key, params) || key;
      return formatMarkdown(translated, params, options, options);
    }
  }
}
